@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=DM+Serif+Display&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.custom-navbar {
  background: transparent;
  background-position: top;
  padding: 0.3rem 3rem 0.3rem 3rem;
  -webkit-transition: all 0.5ms ease-in-out;
  transition: all 0.5ms ease-in-out;
  z-index: 1000;
}

.scrolled {
  background: #fff;
  color: #212B40;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}

.scrolled-nav {
  color: #fff;
}

.brand {
  display: flex !important;
  align-items: center;
  color: rgb(47, 79, 167);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.brand-strong {
  color: #005E90;
  padding-left: 0.5em;
}

.navbar a:hover {
  text-decoration: none;
  color: darkgray;
  cursor: pointer;
}

.brand-scrolled {
  color: #212b40;
}
.home-jumbotron {
  background-image: url("/assets/img/main-banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: relative;
}

.home-jumbotron-althea{
  background-image: url("/assets/img/althea-caregem.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  position: relative;
}

.jumbo-column h2 {
  color: #005E90;
  font-size: 2.2rem;
  margin: 5em 0 0.5em 0;
  text-align: left;
}

h2 {
  margin: 0 0 0.5em 0;
  text-align: left;
}

.h2-right {
  color: #212B40;
  margin-bottom: 0;
  text-align: right;
  display: block;
  font-family: Arial,
    Helvetica,
    sans-serif;
  font-size: 3rem;
  letter-spacing: 1px;
  line-height: 110%;
  text-transform: uppercase;
}

h1 {
  color: rgba(0, 94, 144, 0.8);
  font-size: 3.5rem;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
  text-align: right;
  text-transform: uppercase;
}

h5 {
  text-transform: uppercase;
  padding: 10px 0 0 0;
}

hr {
  border: 1px solid #fff;
  margin: auto;
  width: 50%;
}

.profile hr {
  border: 0.5px solid black;
  margin: auto;
  width: 90%;
}

h6 {
  padding: 5px 0 5px 0;
}

.card-body {
  background-color: rgba(247, 247, 247, .2);
}

.home-intro .Logi {
  color: darkgrey;
  font-family: 'Roboto',
    sans-serif;

  font-size: 3.1rem;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
  text-align: right;
  text-transform: capitalize;
}

.home-intro .Q {
  color: #212B40;
  font-family: 'Roboto',
    sans-serif;
  font-size: 3.1rem;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;
  text-align: right;
  text-transform: capitalize;
}

.home-intro hr {
  border: 2px solid rgba(0, 94, 144, 0.8);
  float: right;
  margin: 1px auto;
  width: 50%;
}

.strong-dark {
  color: #212B40;
}

.strong-light {
  color: #4FB7B3;
}

.strong-wht {
  color: #fff;
}

.text-center-bottom {
  padding-bottom: 0.5em;
}

.tools-challenges .home-col-1 {
  padding-bottom: 0;
}

.script-text {
  color: #fff;
  font-family: 'DM Serif Display',
    serif;
  font-size: 2.5rem;
  margin-bottom: 0;
  padding-bottom: 0;
}

.subheader-challenges {
  color: #C2DB7A;
  padding-bottom: 2px;
}

.home-subtitles {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  padding-bottom: 0.5em;
}

.card-text ul {
  padding-left: 20px;
}

.white {
  color: #fff;
}

.jumbo-row {
  color: #fff;
}

.jumbotron-img {
  align-items: center;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 0;
}

.jumbo-column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding-top: 0;
  margin-top: 0;
}

.jumbo-column p {
  padding-right: 5em;
}

.home-images-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.home-images-2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.content-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.home-p-left {
  align-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2em;
}

.home-p-right {
  align-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  padding-left: 6em;
}

.home-p-left ul {
  padding-left: 0px;
  padding-top: 1em;
}

.home-p-right ul {
  padding-left: 0px;
  padding-top: 1em;
}


.content-bottom {
  margin-bottom: auto;
  margin-top: auto;
}

.home-partners {
  background-color: #212B40;
  color: #fff;
  padding-bottom: 3em;
  padding-top: 3em;
}

.home-patients {
  background-color: #fff;
  color: #212B40;
  padding-bottom: 3em;
  padding-top: 3em;
}

.home-intro {
  background-color: #fff;
  color: #212B40;
  padding-bottom: 3em;
  padding-top: 3em;
}

.home-who-we-are {
  padding-bottom: 3em;
  padding-top: 3em;
}

.home-about {
  background-color: rgba(0, 94, 144, 0.8);
  color: #fff;
  padding-bottom: 4em;
  padding-top: 3em;
}

ul {
  list-style-type: disc;
}

.home-solution .home-col,
.home-solution .home-col-spacer {
  padding-bottom: 0;
  margin-bottom: 0;
}

.home-solution .home-col-solution-1 {
  padding-bottom: 0;
}

.home-solution .home-col-solution-2 {
  padding-top: 15px;
}

.home-who-we-are .category-header,
.home-partners .category-header,
.home-patients .category-header,
.home-about .category-header,
.home-contact .category-header {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3rem;
  letter-spacing: 1px;
  line-height: 110%;
  padding-top: 1em;
  padding-bottom: 14px;
  text-align: center;
  text-transform: uppercase;
}

.home-partners .category-header u,
.home-about .category-header u {
  border-bottom: 2px solid #fff;
  padding-bottom: 1px;
  text-decoration: none;
}

.home-who-we-are .category-header u,
.home-patients .category-header u,
.home-contact .category-header u {
  border-bottom: 2px solid #212B40;
  padding-bottom: 1px;
  text-decoration: none;
}

.home-intro .home-col-spacer,
.home-partners .home-col-spacer,
.home-patients .home-col-spacer,
.home-about .home-col-spacer,
.home-contact .home-col-spacer {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.home-intro .home-col,
.home-who-we-are .home-col,
.home-partners .home-col,
.home-patients .home-col,
.home-about .home-col,
.home-contact .home-col {
  padding: 2em 2em 2em 2em;
}

ol li {
  padding-bottom: 1em;
}

a.anchor {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

.row-challenges {
  padding: 1em 1em 1em 1em;
}

.col-challenges {
  padding-bottom: 5px;
  padding-top: 5px;
}

.partners-accordion {
  border: none;
}

.partners-accordion .card-header {
  background-color: rgba(33, 43, 64, 0.8);
}

.partners-accordion .card-body {
  background-color: rgba(33, 43, 64, 0.8);
  border: none;
  color: #fff;
}

.partners-accordion .btn-link {
  color: #4FB7B3;
  cursor: pointer;
  text-decoration: none;
}

.partners-accordion .btn-link:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.partners-accordion .card {
  background-color: #212B40;
  border: none;
}
h1.aboutHeading {
  margin-right: -20px;
  margin-bottom: 15px;
}
.home-contact {
    background-color: rgba(79, 183, 179, 0.8);
    color: #212B40;
    padding-bottom: 3em;
    padding-top: 3em;
}
.footer-top {
  background-image: url("/assets/img/footer-bkgd.svg");
  background-size: cover;
  font-size: 0.95rem;
  margin-top: 2em;
  padding: 3em 3em 2em 3em;
}

.footer-col {
  margin-top: 2em;
}

.footer-col li {
  text-align: left;
  list-style: none;
}

.footer-caregem-logo {
  float: left;
  padding: 0 0.5em 0 0;
}

.footer-top a {
  color: #212b40;
  text-decoration: none;
}

.footer-top a:hover {
  color: #4fb7b3;
  cursor: pointer;
  text-decoration: none;
}

.bottom-footer-col a {
  color: #fff;
  text-decoration: none;
}

.footer-bottom a:hover {
  cursor: pointer;
  text-decoration: none;
}

.PrivacyPolicyContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
}
.PrivacyPolicyHeading {
  font-size: 24px;
  font-weight: bolder;
}
.PrivacyPolicySubHeading {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  color: #005e90;
}
.PrivacyPolicyText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
.PrivacyPolicyContent {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

