.PrivacyPolicyContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10%;
}
.PrivacyPolicyHeading {
  font-size: 24px;
  font-weight: bolder;
}
.PrivacyPolicySubHeading {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  color: #005e90;
}
.PrivacyPolicyText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
.PrivacyPolicyContent {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
