.custom-navbar {
  background: transparent;
  background-position: top;
  padding: 0.3rem 3rem 0.3rem 3rem;
  transition: all 0.5ms ease-in-out;
  z-index: 1000;
}

.scrolled {
  background: #fff;
  color: #212B40;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}

.scrolled-nav {
  color: #fff;
}

.brand {
  display: flex !important;
  align-items: center;
  color: rgb(47, 79, 167);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.brand-strong {
  color: #005E90;
  padding-left: 0.5em;
}

.navbar a:hover {
  text-decoration: none;
  color: darkgray;
  cursor: pointer;
}

.brand-scrolled {
  color: #212b40;
}