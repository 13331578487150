.footer-top {
  background-image: url("/assets/img/footer-bkgd.svg");
  background-size: cover;
  font-size: 0.95rem;
  margin-top: 2em;
  padding: 3em 3em 2em 3em;
}

.footer-col {
  margin-top: 2em;
}

.footer-col li {
  text-align: left;
  list-style: none;
}

.footer-caregem-logo {
  float: left;
  padding: 0 0.5em 0 0;
}

.footer-top a {
  color: #212b40;
  text-decoration: none;
}

.footer-top a:hover {
  color: #4fb7b3;
  cursor: pointer;
  text-decoration: none;
}

.bottom-footer-col a {
  color: #fff;
  text-decoration: none;
}

.footer-bottom a:hover {
  cursor: pointer;
  text-decoration: none;
}
